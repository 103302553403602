<template>
<div>
    <CRow class="m-3">
        <CCol md="3">
            <CCard>
                <CRow>
                    <CCol md="12">
                        <CImg class="restaurant-img p-2 " src="/img/menu_images/rice.png"></CImg>
                    </CCol>
                    <CCol md="12">
                        <CRow class="p-2">
                            <CCol md="12" sm="12">
                                <div class=" pt-2">
                                    <h6 id="traffic" class="card-title mb-0"><b>White Rice with curries (Rs 350)</b></h6>
                                    <div class="small ">10% Off</div>
                                </div>
                            </CCol>
                            <CCol md="4" sm="12">
                                <div class="rating-arrangment pt-2 ">

                                </div>
                            </CCol>
                        </CRow>

                    </CCol>
                    <CCol md="12">
                        <div class="p-2">
                            <p class="small text-muted">Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s,
                                when an unknown printer took a galley </p>
                        </div>
                        <div class="">
                            <div class="float-left pl-2 pb-3">
                                <CButton color="primary" size="sm" variant="outline">S</CButton>
                                <CButton color="primary" size="sm" variant="outline" class="ml-1">M</CButton>
                                <CButton color="primary" size="sm" variant="outline" class="ml-1 ">L</CButton>
                            </div>
                            <div class="float-right pr-3 pb-3">
                                <CButton color="primary" @click="darkModal = true">Order</CButton>
                            </div>
                        </div>
                    </CCol>
                </CRow>
            </CCard>
        </CCol>
        <CCol md="3">
            <CCard>
                <CRow>
                    <CCol md="12">
                        <CImg class="restaurant-img p-2 " src="/img/menu_images/chicken.png"></CImg>
                    </CCol>
                    <CCol md="12">
                        <CRow class="p-2">
                            <CCol md="12" sm="12">
                                <div class=" pt-2">
                                    <h6 id="traffic" class="card-title mb-0"><b>Grilled chicken with Butter (Rs 350)</b></h6>
                                    <div class="small ">10% Off</div>
                                </div>
                            </CCol>
                            <CCol md="4" sm="12">
                                <div class="rating-arrangment pt-2 ">

                                </div>
                            </CCol>
                        </CRow>

                    </CCol>
                    <CCol md="12">
                        <div class="p-2">
                            <p class="small text-muted">Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s,
                                when an unknown printer took a galley </p>
                        </div>
                        <div class="">
                            <div class="float-left pl-2 pb-3">
                                <CButton color="primary" size="sm" variant="outline">S</CButton>
                                <CButton color="primary" size="sm" variant="outline" class="ml-1">M</CButton>
                                <CButton color="primary" size="sm" variant="outline" class="ml-1 ">L</CButton>
                            </div>
                            <div class="float-right pr-3 pb-3">
                                <CButton color="primary">Order</CButton>
                            </div>
                        </div>
                    </CCol>
                </CRow>
            </CCard>
        </CCol>
        <CCol md="3">
            <CCard>
                <CRow>
                    <CCol md="12">
                        <CImg class="restaurant-img p-2 " src="/img/menu_images/friderice.png"></CImg>
                    </CCol>
                    <CCol md="12">
                        <CRow class="p-2">
                            <CCol md="12" sm="12">
                                <div class=" pt-2">
                                    <h6 id="traffic" class="card-title mb-0"><b>Fried Rice with Mushrooms(Rs 350)</b></h6>
                                    <div class="small ">10% Off</div>
                                </div>
                            </CCol>
                            <CCol md="4" sm="12">
                                <div class="rating-arrangment pt-2 ">

                                </div>
                            </CCol>
                        </CRow>

                    </CCol>
                    <CCol md="12">
                        <div class="p-2">
                            <p class="small text-muted">Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s,
                                when an unknown printer took a galley </p>
                        </div>
                        <div class="">
                            <div class="float-left pl-2 pb-3">
                                <CButton color="primary" size="sm" variant="outline">S</CButton>
                                <CButton color="primary" size="sm" variant="outline" class="ml-1">M</CButton>
                                <CButton color="primary" size="sm" variant="outline" class="ml-1 ">L</CButton>
                            </div>
                            <div class="float-right pr-3 pb-3">
                                <CButton color="primary">Order</CButton>
                            </div>
                        </div>
                    </CCol>
                </CRow>
            </CCard>
        </CCol>
        <CCol md="3">
            <CCard>
                <CRow>
                    <CCol md="12">
                        <CImg class="restaurant-img p-2 " src="/img/menu_images/rice.png"></CImg>
                    </CCol>
                    <CCol md="12">
                        <CRow class="p-2">
                            <CCol md="12" sm="12">
                                <div class=" pt-2">
                                    <h id="traffic" class="card-title mb-0"><b>White Rice with curries (Rs 350)</b></h>
                                    <div class="small ">10% Off</div>
                                </div>
                            </CCol>
                            <CCol md="4" sm="12">
                                <div class="rating-arrangment pt-2 ">

                                </div>
                            </CCol>
                        </CRow>

                    </CCol>
                    <CCol md="12">
                        <div class="p-2">
                            <p class="small text-muted">Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s,
                                when an unknown printer took a galley </p>
                        </div>
                        <div class="">
                            <div class="float-left pl-2 pb-3">
                                <CButton color="primary" size="sm" variant="outline">S</CButton>
                                <CButton color="primary" size="sm" variant="outline" class="ml-1">M</CButton>
                                <CButton color="primary" size="sm" variant="outline" class="ml-1 ">L</CButton>
                            </div>
                            <div class="float-right pr-3 pb-3">
                                <CButton color="primary">Order</CButton>
                            </div>
                        </div>
                    </CCol>
                </CRow>
            </CCard>
        </CCol>
    </CRow>
     <CRow class="m-3">
        <CCol md="3">
            <CCard>
                <CRow>
                    <CCol md="12">
                        <CImg class="restaurant-img p-2 " src="/img/menu_images/rice.png"></CImg>
                    </CCol>
                    <CCol md="12">
                        <CRow class="p-2">
                            <CCol md="12" sm="12">
                                <div class=" pt-2">
                                    <h6 id="traffic" class="card-title mb-0"><b>White Rice with curries (Rs 350)</b></h6>
                                    <div class="small ">10% Off</div>
                                </div>
                            </CCol>
                            <CCol md="4" sm="12">
                                <div class="rating-arrangment pt-2 ">

                                </div>
                            </CCol>
                        </CRow>

                    </CCol>
                    <CCol md="12">
                        <div class="p-2">
                            <p class="small text-muted">Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s,
                                when an unknown printer took a galley </p>
                        </div>
                        <div class="">
                            <div class="float-left pl-2 pb-3">
                                <CButton color="primary" size="sm" variant="outline">S</CButton>
                                <CButton color="primary" size="sm" variant="outline" class="ml-1">M</CButton>
                                <CButton color="primary" size="sm" variant="outline" class="ml-1 ">L</CButton>
                            </div>
                            <div class="float-right pr-3 pb-3">
                                <CButton color="primary" @click="darkModal = true">Order</CButton>
                            </div>
                        </div>
                    </CCol>
                </CRow>
            </CCard>
        </CCol>
        <CCol md="3">
            <CCard>
                <CRow>
                    <CCol md="12">
                        <CImg class="restaurant-img p-2 " src="/img/menu_images/chicken.png"></CImg>
                    </CCol>
                    <CCol md="12">
                        <CRow class="p-2">
                            <CCol md="12" sm="12">
                                <div class=" pt-2">
                                    <h6 id="traffic" class="card-title mb-0"><b>Grilled chicken with Butter (Rs 350)</b></h6>
                                    <div class="small ">10% Off</div>
                                </div>
                            </CCol>
                            <CCol md="4" sm="12">
                                <div class="rating-arrangment pt-2 ">

                                </div>
                            </CCol>
                        </CRow>

                    </CCol>
                    <CCol md="12">
                        <div class="p-2">
                            <p class="small text-muted">Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s,
                                when an unknown printer took a galley </p>
                        </div>
                        <div class="">
                            <div class="float-left pl-2 pb-3">
                                <CButton color="primary" size="sm" variant="outline">S</CButton>
                                <CButton color="primary" size="sm" variant="outline" class="ml-1">M</CButton>
                                <CButton color="primary" size="sm" variant="outline" class="ml-1 ">L</CButton>
                            </div>
                            <div class="float-right pr-3 pb-3">
                                <CButton color="primary">Order</CButton>
                            </div>
                        </div>
                    </CCol>
                </CRow>
            </CCard>
        </CCol>
        <CCol md="3">
            <CCard>
                <CRow>
                    <CCol md="12">
                        <CImg class="restaurant-img p-2 " src="/img/menu_images/friderice.png"></CImg>
                    </CCol>
                    <CCol md="12">
                        <CRow class="p-2">
                            <CCol md="12" sm="12">
                                <div class=" pt-2">
                                    <h6 id="traffic" class="card-title mb-0"><b>Fried Rice with Mushrooms(Rs 350)</b></h6>
                                    <div class="small ">10% Off</div>
                                </div>
                            </CCol>
                            <CCol md="4" sm="12">
                                <div class="rating-arrangment pt-2 ">

                                </div>
                            </CCol>
                        </CRow>

                    </CCol>
                    <CCol md="12">
                        <div class="p-2">
                            <p class="small text-muted">Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s,
                                when an unknown printer took a galley </p>
                        </div>
                        <div class="">
                            <div class="float-left pl-2 pb-3">
                                <CButton color="primary" size="sm" variant="outline">S</CButton>
                                <CButton color="primary" size="sm" variant="outline" class="ml-1">M</CButton>
                                <CButton color="primary" size="sm" variant="outline" class="ml-1 ">L</CButton>
                            </div>
                            <div class="float-right pr-3 pb-3">
                                <CButton color="primary">Order</CButton>
                            </div>
                        </div>
                    </CCol>
                </CRow>
            </CCard>
        </CCol>
        <CCol md="3">
            <CCard>
                <CRow>
                    <CCol md="12">
                        <CImg class="restaurant-img p-2 " src="/img/menu_images/rice.png"></CImg>
                    </CCol>
                    <CCol md="12">
                        <CRow class="p-2">
                            <CCol md="12" sm="12">
                                <div class=" pt-2">
                                    <h id="traffic" class="card-title mb-0"><b>White Rice with curries (Rs 350)</b></h>
                                    <div class="small ">10% Off</div>
                                </div>
                            </CCol>
                            <CCol md="4" sm="12">
                                <div class="rating-arrangment pt-2 ">

                                </div>
                            </CCol>
                        </CRow>

                    </CCol>
                    <CCol md="12">
                        <div class="p-2">
                            <p class="small text-muted">Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s,
                                when an unknown printer took a galley </p>
                        </div>
                        <div class="">
                            <div class="float-left pl-2 pb-3">
                                <CButton color="primary" size="sm" variant="outline">S</CButton>
                                <CButton color="primary" size="sm" variant="outline" class="ml-1">M</CButton>
                                <CButton color="primary" size="sm" variant="outline" class="ml-1 ">L</CButton>
                            </div>
                            <div class="float-right pr-3 pb-3">
                                <CButton color="primary">Order</CButton>
                            </div>
                        </div>
                    </CCol>
                </CRow>
            </CCard>
        </CCol>
    </CRow>

    <!-- Order Modal -->
    <CModal :show.sync="darkModal" :no-close-on-backdrop="true" :centered="true" title="Modal title 2" size="lg" color="dark">

        <CRow>
            <CCol md="12">
                <p>Choice of Size</p>
                <div class="float-left pb-3">
                    <CButton color="primary" size="md" variant="outline">S</CButton>
                    <CButton color="secondary" size="md" variant="outline" class="ml-1">M</CButton>
                    <CButton color="secondary" size="md" variant="outline" class="ml-1 ">L</CButton>
                  
                </div>
                <div class="float-right ">
                      <p>Rs:350</p>
                </div>
            </CCol>
            <CCol md="12">
                <CTextarea label="Special Note" placeholder="Type here..." rows="4" />
            </CCol>
             <CCol md="12">
                <CButton color="primary" size="md" variant="outline">-</CButton>
                    <CButton color="secondary" size="md" disabled variant="outline" class="ml-1">1</CButton>
                    <CButton color="primary" size="md" variant="outline" class="ml-1 ">+</CButton>
            </CCol>
        </CRow>

        <template #header>
            <h6 class="modal-title ">White Rice with curries</h6>
            <CButtonClose @click="darkModal = false" class="text-white" />
        </template>
        <template #footer>
            <CButton @click="darkModal = false" variant="outline" color="primary">Close</CButton>
            <CButton @click="darkModal = false" color="primary">Add 1 to Order (Rs 350)</CButton>
        </template>
    </CModal>
</div>
</template>

<script>
export default {
    data() {
        return {
            darkModal: false
        }
    }
}
</script>

<style scoped>
.restaurant-img {
    object-fit: cover;
    width: 100%;
    height: 150px;
    border-color: #653dcc !important;
}

.location-color {
    color: blueviolet;
}

.rating-arrangment {
    float: right;
}

@media (max-width: 768px) {

    .rating-arrangment {
        float: none;
    }

}
</style>
